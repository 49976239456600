import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import {
  Grid,
  Breadcrumbs,
  Typography,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Switch,
  Table,
  IconButton,
  Stack,
} from '@mui/material';
import { Add, Home } from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { EditIcon, ElectronicsIcon, PasswordResetIcon, TrashIcon, VendingMachineIcon, WacomTabletIcon } from '../../../common/Icons';
import { atualizaEquipamento, carregaEquipamentos, carregaPontosEntrega, deletaEquipamento, generateUpdateTokenEquipamento } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { MachineEquipmentForm } from './components/EquipmentForm';
import { EquipmentTypeDialog } from './components/EquipmentForm/EquipmentTypeDialog';
import { DeskEquipmentForm } from './components/DeskEquipmentForm';
import { Pagination } from '../../../common/Pagination';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { GenerateTokenDialog } from './components/GenerateTokenDialog';
import { ConfirmGenerateTokenDialog } from './components/ConfirmGenerateTokenDialog';
import { FiltersButton } from '../../../common/FiltersButton';
import { EquipmentsTableFilter } from './components/EquipmentsTableFilter';

const EquipmentRow = ({ data, isSempher, handleOpenEditForm, handleUpdateStatus, handleOpenDeleteDialog, handleOpenConfirmGenerateTokenDialog }) => {
  return (
    <TableRow>
      <TableCell align="center">
        {data.tipo === 'maquina' ? <VendingMachineIcon /> : <WacomTabletIcon />}
      </TableCell>
      <TableCell>{data.nome}</TableCell>
      <TableCell>{data.empresa.nome}</TableCell>
      <TableCell>{data.descricao}</TableCell>
      <TableCell>
        <Switch checked={!!data.status} onChange={handleUpdateStatus} disabled={!hasPermission(['admin', 'admin_equipamentos', 'update_equipamentos'])} />
      </TableCell>

      <TableCell align="right" style={{ minWidth: 150 }}>
        {isSempher && (
          <IconButton
            aria-label="Gerar Token para Máquina"
            onClick={() => handleOpenConfirmGenerateTokenDialog(data)}
            disabled={!data.status}
            sx={{ filter: !data.status ? "grayscale(1) brightness(1.1)" : "" }}
          >
            <PasswordResetIcon />
          </IconButton>
        )}

        {hasPermission(['admin', 'admin_equipamentos', 'update_equipamentos']) && (
          <IconButton aria-label="Editar" onClick={() => handleOpenEditForm(data)}>
            <EditIcon />
          </IconButton>
        )}

        {hasPermission(['admin', 'admin_equipamentos', 'delete_equipamentos']) && (
          <IconButton aria-label="Excluir" onClick={() => handleOpenDeleteDialog(data)}>
            <TrashIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

export function ListOfEquipment() {
  const queryClient = useQueryClient();
  const { dadosUsuario } = useAppContext();
  const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

  const [searchParams, setSearchParams] = useSearchParams();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openGenerateTokenDialog, setOpenGenerateTokenDialog] = useState(false);
  const [openConfirmGenerateTokenDialog, setOpenConfirmGenerateTokenDialog] = useState(false);
  const [openMachineForm, setOpenMachineForm] = useState(false);
  const [openDeskForm, setOpenDeskForm] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [openEquipmentTypeModal, setOpenEquipmentTypeModal] = useState(false);

  const searchParamPage = searchParams.get('page');
  const searchParamPerPage = searchParams.get('perPage');

  const page = searchParamPage ? (parseInt(searchParamPage) - 1) : 0;
  const rowsPerPage = searchParamPerPage ? parseInt(searchParamPerPage) : 10;

  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState(0);

  const queryFilters = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    nome: searchParams.get('nome'),
    id_empresas: [searchParams.get('id_empresas')],
    id_ponto_entrega: searchParams.get('id_ponto_entrega'),
    tipo: searchParams.get('tipo'),
    status: searchParams.get('status'),
  };

  const queryKey = ['listaEquipamentos', queryFilters];
  const { data: equipamentosData, isLoading, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      carregaEquipamentos(queryFilters),
  });

  const equipamentos = equipamentosData?.data?.data ?? [];
  const numTotalItems = equipamentosData?.data?.numero_total ?? 0;
  const isLoadingList = isLoading || isPending;

  const { data: empresasData } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: listaEmpresas,
    staleTime: Infinity,
  });

  const { data: pontosDeEntregaData } = useQuery({
    queryKey: ['listPontosDeEntrega'],
    queryFn: carregaPontosEntrega,
    staleTime: Infinity,
  });

  const handleUpdateStatus = useMutation({
    mutationFn: (data) => atualizaEquipamento(data.id, data.dataToUpdate),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: queryKey });
      // get from cached data, descobrir forma de encontrar qual item foi atualizado.

      if (typeof variables?.dataToUpdate.status === 'boolean') {
        const status = variables?.dataToUpdate.status ? 'ativado' : 'inativado';
        exibirAlerta('Sucesso', `Equipamento ${status} com sucesso`, 'success');
      }
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao executar operação';
      exibirAlerta('Erro', message, 'error');
    }
  });

  const { mutateAsync: handleGenerateUpdateToken, isPending: isLoadingUpdateToken } = useMutation({
    mutationFn: async (data) => await generateUpdateTokenEquipamento(data.id),
    onSuccess: (response) => {
      // get token from response
      const token = response?.data?.token;
      if (token) {
        setSelectedEquipment((prevState) => ({ ...prevState, token }));
        setOpenGenerateTokenDialog(true);
      }
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao gerar token';
      exibirAlerta('Erro', message, 'error');
    }
  });

  function handleOpenForm(equipmentType) {
    if (equipmentType) {
      switch (equipmentType) {
        case 'maquina':
          setOpenMachineForm(true);
          break;
        case 'balcao':
          setOpenDeskForm(true);
          break;
        default:
          exibirAlerta('Erro', 'Tipo de equipamento inválido', 'error');
          break;
      }
    }
  }

  const handleEdit = (equipamento) => {
    setSelectedEquipment(equipamento);
    handleOpenForm(equipamento.tipo);
  };

  const handleOpenDeleteDialog = (item) => {
    setSelectedEquipment(item);
    setOpenDeleteDialog(true);
  };

  const handleOpenConfirmGenerateTokenDialog = (item) => {
    setSelectedEquipment(item);
    setOpenConfirmGenerateTokenDialog(true);
  };

  const handleOpenGenerateTokenDialog = async (item) => {
    try {
      exibirDialog('Gerando token. Aguarde...');
      setSelectedEquipment(item);
      setOpenConfirmGenerateTokenDialog(false);
      // endpoint generate token
      await handleGenerateUpdateToken({ id: item.id });
    } catch (error) {
      console.error('ListOfEquipment ~ error:', error);
    } finally {
      handleCloseDialog();
    }
  };

  const { mutateAsync: handleDelete } = useMutation({
    mutationFn: deletaEquipamento,
    onSuccess: (response, id) => {
      const message = response.data.message ?? 'Equipamento excluído com sucesso';
      exibirAlerta('Sucesso', message, 'success');

      if (message === 'O equipamento indicado(a) possui relação com outros itens. Por isso foi inativado') {
        // como o equipamento não é excluído dependendo dos seus relacionamentos, 
        // nao é possível saber se excluiu ou se atualizou o status.
        queryClient.invalidateQueries({ queryKey: queryKey });
      } else {
        const cached = queryClient.getQueryData(queryKey);
        const updatedData = {
          ...cached,
          data: {
            ...cached.data,
            data: cached.data.data.filter((item) => item.id !== id),
          },
        };
        queryClient.setQueryData(queryKey, updatedData);
      }
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao executar operação';
      exibirAlerta('Erro', message, 'error');
    },
    onSettled: () => {
      setOpenDeleteDialog(false);
      setSelectedEquipment(null);
    },
  });

  useEffect(() => {
    checkPermissionsAndRedirect(['admin', 'admin_equipamentos', 'list_equipamentos']);

    setSearchParams((state) => {
      state.set('page', String(page + 1));
      state.set('perPage', String(rowsPerPage));
      return state;
    });
  }, [page, rowsPerPage, setSearchParams]);

  useEffect(() => {
    if (openMachineForm || openDeskForm) return;
    setSelectedEquipment(null);

    const queryFilters = { ...Object.fromEntries(searchParams) }

    const tableFilters = { ...queryFilters };
    delete tableFilters.page;
    delete tableFilters.perPage;

    const activeFilters = Object.keys(tableFilters).length;

    setActiveFilters(activeFilters);
    setIsOpenFilters(activeFilters > 0);
  }, [openMachineForm, openDeskForm, searchParams]);

  return (
    <>
      <Helmet title='Equipamentos' defer={false} />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="end"
        spacing={3}
      >
        <Grid item xs={12} md={9}>
          <Breadcrumbs>
            <LinkNavegacao to="/"><Home fontSize="small" /></LinkNavegacao>
            <LinkNavegacao to="/epis">Gestão de EPI's</LinkNavegacao>
            <Typography variant='span'>Equipamentos</Typography>
          </Breadcrumbs>
          <PageTitle
            icon={<ElectronicsIcon fontSize='large' />}
            title="Equipamentos"
            description="Gerencie os equipamentos das empresas."
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent={'space-between'}>
            <FiltersButton
              isOpenFilters={isOpenFilters}
              setIsOpenFilters={setIsOpenFilters}
              activeFilters={activeFilters}
            />

            {hasPermission(["admin", "admin_equipamentos", "create_equipamentos"]) &&
              <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => setOpenEquipmentTypeModal(true)}
                  disabled={!hasPermission(["admin", "admin_equipamentos", "create_equipamentos"])}
                >
                  Cadastrar Equipamento
                </Button>
              </Typography>
            }
          </Stack>
        </Grid>

        <EquipmentsTableFilter
          isOpenFilters={isOpenFilters}
          isLoadingList={isLoadingList}
          empresas={empresasData?.data?.data || []}
          pontosDeEntrega={pontosDeEntregaData?.data?.data || []}
        />

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="Equipamentos">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingList ? (
                  <SkeletonTableRow
                    numCells={6}
                    numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                    firstCellIsIcon
                  />
                ) : equipamentos.length > 0 ? (
                  equipamentos.map((equipamento) => (
                    <EquipmentRow
                      key={equipamento.id}
                      data={equipamento}
                      isSempher={dadosUsuario?.isSempher ?? false}
                      handleOpenEditForm={handleEdit}
                      handleUpdateStatus={() => {
                        const dataSendUpdate = { id: equipamento.id, dataToUpdate: { status: !equipamento.status } }
                        handleUpdateStatus.mutateAsync(dataSendUpdate)
                      }}
                      handleOpenDeleteDialog={() => handleOpenDeleteDialog(equipamento)}
                      handleOpenConfirmGenerateTokenDialog={() => handleOpenConfirmGenerateTokenDialog(equipamento)}
                    />
                  ))
                ) : (
                  <EmptyTableRow />
                )}
              </TableBody>
            </Table>

            <Pagination
              page={page}
              rowsPerPage={rowsPerPage}
              numTotalItems={numTotalItems}
            />
          </TableContainer>
        </Grid>
      </Grid>

      {/* Dialog (Modal) para usuário selecionar na edição se irá cadastrar maquina ou balcão */}
      <EquipmentTypeDialog
        open={openEquipmentTypeModal}
        setOpen={setOpenEquipmentTypeModal}
        handleOpenModalEquipment={(equipmentType) => handleOpenForm(equipmentType)}
      />

      {/* Formulário para equipamento do tipo Maquina */}
      <MachineEquipmentForm
        open={openMachineForm}
        setOpen={setOpenMachineForm}
        equipamento={selectedEquipment}
        empresas={empresasData?.data?.data ?? []}
        queryKey={queryKey}
      />

      {/* Formulário para equipamento do tipo Balcão */}
      <DeskEquipmentForm
        open={openDeskForm}
        setOpen={setOpenDeskForm}
        equipamento={selectedEquipment}
        empresas={empresasData?.data?.data ?? []}
        queryKey={queryKey}
      />

      <ConfirmDialog
        title="Excluir Equipamento"
        description="Tem certeza que deseja excluir este equipamento?"
        goAction={() => handleDelete(selectedEquipment.id)}
        handleClose={() => {
          setOpenDeleteDialog(false);
          setSelectedEquipment(null);
        }}
        state={openDeleteDialog}
      />

      <ConfirmGenerateTokenDialog
        open={openConfirmGenerateTokenDialog}
        selectedEquipment={selectedEquipment}
        goAction={() => handleOpenGenerateTokenDialog(selectedEquipment)}
        handleClose={() => {
          setOpenConfirmGenerateTokenDialog(false);
          setSelectedEquipment(null);
        }}
        isLoading={isLoadingUpdateToken}
      />

      <GenerateTokenDialog
        open={openGenerateTokenDialog}
        selectedEquipment={selectedEquipment}
        handleClose={() => {
          setOpenGenerateTokenDialog(false);
          setSelectedEquipment(null);
        }}
      />
    </>
  );
}